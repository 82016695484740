<template>
	<div>
		<contentHeader title="Pembelian Obat" url="/pembelian-obat" subTitle="Tambah Pembelian Obat" />

		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
								<h3 class="card-title col-6">Pembelian Obat</h3>
							</div>
							<form @submit.prevent="prosesPembelian">
								<div class="card-body">
									<div class="px-lg-2">
										<div class="row">
											<div class="col">
												<div class="form-group">
												<label for="">No. Faktur <span class="text-danger">*</span></label>
												<input type="text" class="form-control" v-model="pembelian.nomor_faktur" required="">
												</div>
											</div>
											<div class="col">
												<div class="form-group">
												<label for="">Supplier <span class="text-danger">*</span></label>
												<multiselect style="min-width: 150px;" 
													v-model="supplier_select_value"
													deselect-label="Can't remove this value"
													track-by="nama"
													label="nama"
													placeholder="Pilih Supplier"
													:loading="loading_supplier_select"
													:options="cabang"
													:allow-empty="false"
													@update:model-value="pilihCabang"
													>
												</multiselect>
												</div>
											</div>
										</div>

										<div class="row">
											<div class="col">
												<div class="form-group">
													<label for="" class="block">Tanggal Faktur</label><br>
													<el-date-picker
													v-model="pembelian.tanggal_faktur"
													type="datetime"
													placeholder="Pilih Tanggal Faktur"
													format="DD/MM/YYYY"
													value-format="YYYY-MM-DD"
													>
													</el-date-picker>
												</div>
											</div>
											<div class="col">
												<label for="">Jatuh Tempo</label>
												<br>
												<el-date-picker v-model="pembelian.jatuh_tempo" type="date" placeholder="Pilih Jatuh Tempo" format="DD/MM/YYYY" value-format="YYYY-MM-DD"></el-date-picker>
											</div>
										</div>

										<div class="table-responsive">
											<table class="table table-bordered table-hover mt-2">
												<thead>
													<tr>
														<th scope="col" class="text-sm" style="min-width: 200px;">NAMA OBAT</th>
														<th scope="col" class="text-sm" style="min-width: 150px;">HARGA BELI</th>
														<th scope="col" class="text-sm" style="min-width: 150px;">JUMLAH</th>
														<th scope="col" class="text-sm" style="min-width: 150px;">EXPIRED DATE</th>
														<th scope="col" class="text-sm" style="min-width: 150px;">MARGIN</th>
														<th scope="col" class="text-sm" style="min-width: 150px;">HARGA JUAL</th>
														<th scope="col" class="text-sm" style="min-width: 150px;">DISKON</th>
														<th scope="col" class="text-sm" style="min-width: 150px;">TOTAL (RP)</th>
														<th scope="col" class="text-sm" style="width: 80px;"></th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="row in produk_dibeli" :key="row.id">
														<td style="min-width: 200px;">
															<input class="form-control" type="text" name="" disabled="" v-model="row.nama_produk">
														</td>
														<td style="min-width: 150px;">
															<input class="form-control" type="number" name="" disabled="" v-model="row.harga_beli">
														</td>
														<td style="min-width: 150px;">
															<input class="form-control" type="number" name="" disabled="" v-model="row.jumlah">
														</td>
														<td width="100px">
															<input class="form-control" type="date" name="" disabled="" v-model="row.expired_date">
														</td>
														<td width="50px">
															<input class="form-control" type="number" name="" disabled="" v-model="row.margin_harga_jual">
														</td>

														<td style="min-width: 150px;">
															<input class="form-control" type="number" name="" disabled="" v-model="row.harga_jual">
														</td>
														<td style="min-width: 150px;">
															<input type="number" class="form-control" step="any" v-model="row.diskon" disabled="">
														</td>
														<td style="min-width: 150px;">
															<input type="number" class="form-control" step="any" v-model="row.total" disabled="">
														</td>
														<td></td>
													</tr>

													<tr>
														<td style="min-width: 200px;">
															<el-select placeholder="Pilih Produk" filterable v-model="pembelian_baru.produk_id" @change="pilihProduk">
																<el-option v-for="(row, index) in produk" :key="index" :value="row.id" :label="row.nama" ></el-option>
															</el-select>
														</td>
														<td style="min-width: 150px;">
															<input type="number" name="" class="form-control" v-model="pembelian_baru.harga_beli">
														</td>
														<td style="min-width: 150px;">
															<input type="number" name="" class="form-control" min="1" v-model="pembelian_baru.jumlah" @change="hitungTotalPembelianBaru">
														</td>
														<td style="min-width: 150px;">
															<input type="date" name="" class="form-control"  v-model="pembelian_baru.expired_date">
														</td>
														<td style="min-width: 150px;">
															<input type="number" name="" class="form-control" min="0" max="100" v-model="pembelian_baru.margin_harga_jual" @change="hitungHargaJual">
														</td>
														<td style="min-width: 150px;">
															<input type="number" name="" class="form-control" min="0" v-model="pembelian_baru.harga_jual">
														</td>
														<td style="min-width: 150px;">
															<div class="input-group mb-3">
															<div class="input-group-prepend">
																<div class="btn btn-secondary">{{ pembelian_baru.label_diskon }}</div>
															</div>
															<!-- /btn-group -->
															<input type="number" class="form-control" step="any" v-model="pembelian_baru.diskon" @keyup="hitungTotalPembelianBaru">
															</div>
														</td>
														<td style="min-width: 150px;">
															<input type="number" class="form-control" disabled="" v-model="pembelian_baru.total">
														</td>
														<td style="width: 80px;"><i @click="tambahPembelian" class="fas fa-plus-circle fa-2x text-secondary" style="cursor: pointer;"></i></td>
													</tr>
												</tbody>
											</table>
										</div>

										<div class="row mt-2">
											<div class="col">
												<label class="text-sm">Sub Total</label>
												<div class="input-group mb-3">
											<div class="input-group-prepend">
												<div class="btn btn-secondary">Rp. </div>
											</div>
											<!-- /btn-group -->
											<input type="number" class="form-control" disabled="" v-model="pembelian.sub_total">
											</div>
											</div>
											<div class="col">
												<label class="text-sm">Diskon</label>
												<div class="input-group mb-3">
											<div class="input-group-prepend">
												<div class="btn btn-secondary">{{ pembelian.label_diskon }}</div>
											</div>
											<!-- /btn-group -->
											<input type="number" class="form-control" step="any" v-model="pembelian.diskon" @keyup="hitungTotalPembelian">
											</div>
											</div>
											<div class="col">
												<label class="text-sm">PPN</label>
												<div class="input-group mb-3">
											<div class="input-group-prepend">
												<div class="btn btn-secondary">{{ pembelian.label_ppn }}</div>
											</div>
											<!-- /btn-group -->
											<input type="number" class="form-control" step="any" v-model="pembelian.ppn" @keyup="hitungTotalPembelian">
											</div>
											</div>
											<div class="col">
												<label class="text-sm">Ongkos Kirim</label>
												<div class="input-group mb-3">
											<div class="input-group-prepend">
												<div class="btn btn-secondary">Rp. </div>
											</div>
											<!-- /btn-group -->
											<input type="number" class="form-control" step="any" v-model="pembelian.ongkos_kirim" @keyup="hitungTotalPembelian">
											</div>
											</div>
										</div>

										<div class="row">
											<div class="col col-lg-3">
												<label class="text-sm">Total</label>
												<div class="input-group mb-3">
											<div class="input-group-prepend">
												<div class="btn btn-secondary">Rp. </div>
											</div>
											<!-- /btn-group -->
											<input type="number" class="form-control" step="any" v-model="pembelian.total" disabled="">
											</div>
											</div>
										</div>

										<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											<span class="sr-only">Loading...</span>
											</div>
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'
	import Multiselect from '@suadelabs/vue3-multiselect'

	import { onMounted, ref, computed, reactive } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'
	import router from '@/router'

	const user = computed(() => store.getters['auth/user'])
	const cabangKlinik = computed(() => store.getters['auth/cabang'])
	const pembelian = reactive({
		"pembeli_id" : null,
		"nama_pembeli" : null,
		"penjual_id" : null,
		"nama_penjual" : null,
		"nomor_faktur" : null,
		"produk" : [],
		"sub_total" : null,
		"label_diskon" : "...",
		"diskon" : null,
		"label_ppn" : "...",
		"ppn" : null,
		"ongkos_kirim" : null,
		"total" : null,
		"jatuh_tempo" : null,
		"tanggal_faktur" : null
	})
	const produk_dibeli = ref([])
	const pembelian_baru = reactive({
		"produk_id" : null,
		"nama_produk" : null,
		"jenis" : null,
		"harga_beli" : null,
		"margin_harga_jual" : null,
		"harga_jual" : null,
		"jumlah" : null,
		"expired_date" : null,
		"label_diskon" : "...",
		"diskon" : null,
		"total" : null
	})
	const cabang = ref([])
	const produk = ref([])
	const loading = ref(false)
	const loading_produk_select = ref(true)
	const loading_supplier_select = ref(true)
	const produk_select_value = ref('')
	const supplier_select_value = ref('')


	const getCabang = async () => {
		let { data } = await axios.get(`api/supplier/get/${user.value.cabang_id}`)
		
		if (data == 'kosong') {
			loading_supplier_select.value = false
			cabang.value = []
		} else {
			loading_supplier_select.value = false
			cabang.value = data
		}
	}

	const getPembeli = async () => {
		let { data } = await axios.get(`api/cabang/getWhereId/${user.value.cabang_id}`)

		pembelian.pembeli_id = data.id
		pembelian.nama_pembeli = data.nama
	}

	const getProduk = async () => {
		let { data } = await axios.get(`api/produk/get/${cabangKlinik.value.id}/${cabangKlinik.value.tipe}`)

		if (data == 'kosong') {
			loading_produk_select.value = false
			produk.value = []
		} else {
			loading_produk_select.value = false
			produk.value = data
		}

	}

	const pilihCabang = (value) => {
		pembelian.penjual_id = value.id

		for (var i = 0; i < cabang.value.length; i++) {
			if (cabang.value[i].id == value.id) {
				pembelian.nama_penjual = cabang.value[i].nama
			}
		}
	}

	// GENERATE HARGA JUAL
	const hitungHargaJual = () => {
		if (pembelian_baru.harga_beli > 0 && pembelian_baru.margin_harga_jual > 0) {
			let margin = ((pembelian_baru.harga_beli * pembelian_baru.margin_harga_jual) / 100)
			pembelian_baru.harga_jual = parseInt(pembelian_baru.harga_beli) + margin
		} else {
			pembelian_baru.harga_jual = pembelian_baru.harga_beli
		}
	}

	// PEMBELIAN BARU
	const pilihProduk = () => {			
		for (var i = 0; i < produk.value.length; i++) {
			if (produk.value[i].id == pembelian_baru.produk_id) {
				pembelian_baru.nama_produk = produk.value[i].nama
				pembelian_baru.jenis = produk.value[i].jenis
				pembelian_baru.harga_beli = produk.value[i].harga_beli
			}
		}
	}

	const hitungTotalPembelianBaru = () => {
		pembelian_baru.total = pembelian_baru.harga_beli * pembelian_baru.jumlah

		if (pembelian_baru.diskon != '' && pembelian_baru.diskon > 0) {
			if (pembelian_baru.diskon <= 100) {
				pembelian_baru.label_diskon = 'Persen (%)'
				let diskon = (pembelian_baru.total * pembelian_baru.diskon) / 100
				pembelian_baru.total = pembelian_baru.total - diskon
			} else {
				pembelian_baru.label_diskon = 'Rp. '
				let diskon = pembelian_baru.diskon
				pembelian_baru.total = pembelian_baru.total - diskon
			}
		} else {
				pembelian_baru.label_diskon = '...'
		}
	}

	const tambahPembelian = () => {
		let label = '...'
		produk_select_value.value = ''

		if (pembelian_baru.diskon != '') {
			if (pembelian_baru.diskon <= 100) {
				label = 'Persen (%)'
				pembelian_baru.diskon = (pembelian_baru.total * pembelian_baru.diskon) / 100
			} else {
				label = 'Rp. '
			}
		} 

		produk_dibeli.value.push({
			"produk_id" : pembelian_baru.produk_id,
			"nama_produk" : pembelian_baru.nama_produk,
			"jenis" : pembelian_baru.jenis,
			"harga_beli" : pembelian_baru.harga_beli,
			"jumlah" : pembelian_baru.jumlah,
			"expired_date" : pembelian_baru.expired_date,
			"margin_harga_jual" : pembelian_baru.margin_harga_jual,
			"harga_jual" : pembelian_baru.harga_jual,
			"label_diskon" : label,
			"diskon" : pembelian_baru.diskon,
			"total" : pembelian_baru.total
		})

		pembelian_baru.produk_id = ''
		pembelian_baru.nama_produk = ''
		pembelian_baru.jenis = ''
		pembelian_baru.harga_beli = ''
		pembelian_baru.jumlah = ''
		pembelian_baru.expired_date = ''
		pembelian_baru.margin_harga_jual = ''
		pembelian_baru.harga_jual = ''
		pembelian_baru.label_diskon = '...'
		pembelian_baru.diskon = ''
		pembelian_baru.total = ''

		pembelian.sub_total = 0
		pembelian.total = 0
		for (var i = 0; i < produk_dibeli.value.length; i++) {
			pembelian.sub_total = pembelian.sub_total + produk_dibeli.value[i].total
			pembelian.total = pembelian.sub_total
		}
	}
	// END PEMBELIAN BARU

	const hitungTotalPembelian = () => {
		pembelian.total = pembelian.sub_total

		if (pembelian.diskon != '') {
			if (pembelian.diskon <= 100) {
				pembelian.label_diskon = 'Persen (%)'
				let diskon = (pembelian.sub_total * pembelian.diskon) / 100
				pembelian.total = pembelian.total - diskon
			} else {
				pembelian.label_diskon = 'Rp. '
				let diskon = pembelian.diskon
				pembelian.total = pembelian.total - diskon
			}
		} else {
			pembelian.label_diskon = '...'
		}

		if (pembelian.ppn != '') {
			if (pembelian.ppn <= 100) {
				pembelian.label_ppn = 'Persen (%)'
				let ppn = (pembelian.sub_total * pembelian.ppn) / 100
				pembelian.total = pembelian.total + ppn
			} else {
				pembelian.label_ppn = 'Rp. '
				let ppn = pembelian.ppn
				pembelian.total = pembelian.total + ppn
			}
		} else {
			pembelian.label_ppn = '...'
		}

		if (pembelian.ongkos_kirim != '') {
			pembelian.total = pembelian.total + pembelian.ongkos_kirim
		}
	}

	const prosesPembelian = () => {
		loading.value = true
		pembelian.produk = produk_dibeli.value
		
		axios.post(`api/pembelian/pembelianKeSupplier/${cabangKlinik.value.tipe}`, pembelian)
		.then(() => {
			Swal.fire({
				title: 'Berhasil!',
				text: 'Berhasil Menambahkan Data',
				icon: 'success',
				showConfirmButton: false,
				timer: 1500
			})
			loading.value = false
			router.push('/pembelian-obat')
		})
		.catch(() => {
			Swal.fire({
				title: 'Gagal!',
				text: 'Gagal menambahkan data',
				icon: 'error',
				confirmButtonText: 'Ok'
			})	
			loading.value = false
		})
	}


	onMounted(() => {
		getCabang()
		getPembeli()
		getProduk()
	})
</script>